import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Paper,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import DeleteIcon from "@mui/icons-material/Delete";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import "./PreCheckScreen.css";

const PreCheckPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItems } = location.state;

  const [deliveryOption, setDeliveryOption] = useState("home");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: 0.3476, lng: 32.5825 });
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [address, setAddress] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [distance, setDistance] = useState(null);
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [shippingPerKm, setShippingPerKm] = useState(0); 
  const [subTotalPrice, setSubTotalPrice] = useState(location.state.totalPrice); // Initialize from location.state
  const [deliveryFee, setDeliveryFee] = useState(0); // New state for delivery fee
  const [totalPrice, setTotalPrice] = useState(location.state.totalPrice); // Initialize from location.state
  const [selectedCardId, setSelectedCardId] = useState(
    savedAddresses.length > 0 ? savedAddresses[0].id : null
  );
  


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDwOwz4ybDE8SjOUP3dydRFgsigu2nOAmw",
    libraries: ["places"],
  });

  const dispatchLocation = {
    lat: 0.3198, // Garden City Latitude
    lng: 32.5919, // Garden City Longitude
  };

  // Regular expression for validating phone number with optional country code
  const phoneRegex = /^\+?[1-9]{1}[0-9]{1,4}[\s\-]?[0-9]{6,12}$|^[0-9]{10}$/; // Matches both international and 10 digit formats

  useEffect(() => {
    // Fetch configuration for shipping rate
    fetch("https://admin.aristoc.co.ug:9443/api/v1/config")
      .then((response) => response.json())
      .then((data) => {
        const shippingRate = data?.delivery_management?.shipping_per_km;
        if (shippingRate) {
          setShippingPerKm(shippingRate);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch shipping rate:", error);
      });
  }, []);

  // Haversine formula to calculate distance
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLng = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  useEffect(() => {
    if (latitude && longitude) {
      const calculatedDistance = calculateDistance(
        dispatchLocation.lat,
        dispatchLocation.lng,
        parseFloat(latitude),
        parseFloat(longitude)
      );
      setDistance(calculatedDistance.toFixed(2)); // Round to 2 decimal places
      if (shippingPerKm) {
        setDeliveryFee((calculatedDistance * shippingPerKm).toFixed(2)); // Calculate delivery fee
      }
    }
  }, [latitude, longitude, shippingPerKm]);

  useEffect(() => {
    // Update total price whenever subTotalPrice or deliveryFee changes
    setTotalPrice((parseFloat(subTotalPrice) + parseFloat(deliveryFee)).toFixed(2));
  }, [subTotalPrice, deliveryFee]);

  useEffect(() => {
    // Update total price whenever subTotalPrice or deliveryFee changes
    setTotalPrice((parseFloat(subTotalPrice) + parseFloat(deliveryFee)).toFixed(2));
  }, [subTotalPrice, deliveryFee]);

  useEffect(() => {
    // Fetch saved addresses from the API
    const fetchSavedAddresses = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No auth token found");
        return;
      }

      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/customer/address/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSavedAddresses(data); // Save addresses to state
        } else {
          console.error("Failed to fetch saved addresses");
        }
      } catch (error) {
        console.error("Error fetching saved addresses:", error);
      }
    };

    fetchSavedAddresses();
  }, []);

  // Delete address
  const handleDeleteAddress = async (addressId) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("You are not logged in. Please login first.");
      return;
    }

    try {
      const response = await fetch(
        "https://admin.aristoc.co.ug:9443/api/v1/customer/address/delete",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ address_id: addressId }),
        }
      );

      if (response.ok) {
        alert("Address deleted successfully!");
        // Refresh the saved addresses list
        const updatedAddresses = savedAddresses.filter(
          (addr) => addr.id !== addressId
        );
        setSavedAddresses(updatedAddresses);
      } else {
        console.error("Failed to delete address");
        alert("Failed to delete the address. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting address:", error);
      alert("An error occurred while deleting the address.");
    }
  };
  
  const handleDeliveryOptionChange = (event) =>
    setDeliveryOption(event.target.value);

  const handleDeliveryNoteChange = (event) =>
    setDeliveryNote(event.target.value);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCoordinates({ lat, lng });
    setLatitude(lat);
    setLongitude(lng);
    setAddress(""); // Reset address when map is clicked
  };

  const handleAddButtonClick = () => {
    if (!address || !longitude || !latitude) {
      alert("Please search and select an address first!");
      return;
    }
    setIsModalOpen(true);
  };

  const handlePlaceSelected = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCoordinates({ lat, lng });
        setAddress(place.formatted_address);
        setLongitude(lng);
        setLatitude(lat);
      }
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSaveAddress = async () => {
    // Validate phone number
    if (!phoneRegex.test(contactPersonNumber)) {
      alert(
        "Please enter a valid phone number (e.g., 10 digits or with country code)."
      );
      return;
    }

    if (
      !address ||
      !longitude ||
      !latitude ||
      !addressType ||
      !contactPersonName ||
      !contactPersonNumber
    ) {
      alert("Please fill all required fields.");
      return;
    }

    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/my-account");
      return;
    }

    const payload = {
      address_type: addressType,
      contact_person_number: contactPersonNumber,
      address: address,
      latitude: latitude,
      longitude: longitude,
      contact_person_name: contactPersonName,
      road: "g",
      floor: "g",
      house: "t",
    };

    fetch("https://admin.aristoc.co.ug:9443/api/v1/customer/address/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          alert("Address saved successfully!");
          handleCloseModal();
          window.location.reload();
        } else {
          return response.json().then((error) => {
            alert(`Error: ${error.message || "Failed to save address."}`);
          });
        }
      })
      .catch((err) => {
        console.error("Failed to save address:", err);
        alert("An error occurred while saving the address.");
      });
  };

  const handleSubmit = () => {
    if (!selectedCardId) {
      alert("Please select an address before proceeding.");
      setShowAddressForm(true);
      return;
    }
  
    const selectedAddress = savedAddresses.find(addr => addr.id === selectedCardId);
    
    if (!selectedAddress) {
      alert("The selected address is invalid. Please try again.");
      return;
    }
    
    navigate("/checkout", {
      state: { cartItems, totalPrice, address, distance, deliveryFee },
    });
  };

  if (!isLoaded) return <div>Loading...</div>;



  const handleCardClick = (addr) => {
    // Update selected card ID
    setSelectedCardId(addr.id);
  
    // Calculate distance
    const calculatedDistance = calculateDistance(
      dispatchLocation.lat,
      dispatchLocation.lng,
      parseFloat(addr.latitude),
      parseFloat(addr.longitude)
    );
    setDistance(calculatedDistance.toFixed(2)); // Update distance state
  
    // Calculate delivery fee
    const calculatedFee = (calculatedDistance * shippingPerKm).toFixed(2);
    setDeliveryFee(calculatedFee); // Update delivery fee state
  };
  

    
  return (
    <>
      <TopBar />
      <div className="precheck-container">
        <div className="precheck-map-section">
          <GoogleMap
            mapContainerClassName="precheck-map-container"
            center={coordinates}
            zoom={13}
            onClick={handleMapClick}
          >
            <Marker position={coordinates} />
          </GoogleMap>

          <div style={{ padding: "20px" }}>
      {showAddressForm ? (
        // Address Input Form
        <div>
          <p>Deliver to:</p>
          <Autocomplete
            onLoad={(autocomplete) => setAutocomplete(autocomplete)}
            onPlaceChanged={handlePlaceSelected}
          >
            <TextField
              label="Search Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />
          </Autocomplete>
          <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
            Save
          </Button>
        </div>
      ) : (
        // Saved Address Cards
        <Grid container spacing={2} justifyContent="center">
          {savedAddresses.map((addr) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={addr.id}
              style={{ display: "flex", justifyContent: "center" }}
            >
             <Card key={addr.id}
          onClick={() => handleCardClick(addr)}
          style={{
            width: "280px",
            height: "165px",
            position: "relative",
            border: addr.id === selectedCardId ? "2px solid red" : "1px solid #ddd",
            cursor: "pointer",
          }}>
  <CardContent>
    <Typography variant="h6" style={{ fontSize: "16px" }}>
      {addr.address_type} - {addr.contact_person_name}
    </Typography>
    <Typography variant="body2" style={{ fontSize: "14px" }}>
      {addr.address}
    </Typography>
    <Typography variant="body2" style={{ fontSize: "14px" }}>
      Contact: {addr.contact_person_number}
    </Typography>
    <hr style={{ margin: "10px 0" }} />
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "10px",
        left: "10px",
        right: "10px",
      }}
    >
      <Button
        variant="outlined"
        size="small"
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setShowAddressForm(true);
        }}
      >
        Change
      </Button>
      <IconButton
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          handleDeleteAddress(addr.id);
        }}
        style={{ color: "red" }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  </CardContent>
</Card>


            </Grid>
          ))}
        </Grid>
      )}
    </div>
        </div>

        <div className="precheck-form-section">
          <Paper className="precheck-paper">
            <Typography variant="h6" className="precheck-typography-heading">
              Checkout
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={deliveryOption}
                onChange={handleDeliveryOptionChange}
              >
                <FormControlLabel
                  value="home"
                  control={<Radio />}
                  label="Home Delivery"
                />
                <FormControlLabel
                  value="pickup"
                  control={<Radio />}
                  label="Self Pickup"
                />
              </RadioGroup>
            </FormControl>

            <TextField
              label="Delivery Note"
              value={deliveryNote}
              onChange={handleDeliveryNoteChange}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />

            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="I confirm that my details are correct"
            />

            <div className="precheck-total-amount">
              <Typography variant="body1">
                <strong>Subtotal:</strong> {subTotalPrice} UGX
              </Typography>
              <Typography variant="body1">
              <strong>Delivery Fee:</strong>{" "}
              {deliveryFee ? `${deliveryFee} UGX` : "Calculating..."}
              </Typography>
              <Typography variant="h6" style={{ marginTop: "10px" }}>
              <strong>Total Amount:</strong> {totalPrice ? `${totalPrice} UGX` : "Calculating..."}
              </Typography>
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Proceed to Payment
            </Button>
          </Paper>
        </div>
      </div>

      {/* Modal for Address Details */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Address Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Address Name"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Address Type"
                value={addressType}
                onChange={(e) => setAddressType(e.target.value)}
                SelectProps={{ native: true }}
                fullWidth
              >
                <option value="Home">Home</option>
                <option value="Work">Work</option>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Person Name"
                value={contactPersonName}
                onChange={(e) => setContactPersonName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Person Number"
                value={contactPersonNumber}
                onChange={(e) => setContactPersonNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Longitude"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Latitude"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Distance to Garden City Mall:{" "}
                {distance ? `${distance} km` : "Calculating..."}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveAddress}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
};

export default PreCheckPage;
